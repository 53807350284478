.parts-selector {
	&__options {
		text-align: right;
		
		@media screen and (min-width: 992px) {
			.btn {
				margin-top: 26px;
			}
		}
	}
}
