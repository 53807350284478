.authentication__login__container, .authentication__password-reset__container, .authentication__password-change-token__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    flex-direction: column;
}

.authentication__login__form, .authentication__password-reset__form, .authentication__password-change-token__form {
    width: 100%;
    max-width: 300px;
}

.authentication__logo {
    margin-bottom: 30px;
}
