@import 'panel.scss';
@import 'parts-selector.scss';
@import 'authentication.scss';


[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

html, body, .full-height {
    height: 100%;
}



.container {
	min-height: 80%;
}

