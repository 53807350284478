.pagination a {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .pagination li .arrow a, .pagination li .arrow span {
    min-width: 30px;
  }
  .pagination li a, .pagination li span {
    padding-left: 0;
    padding-right: 0;
    min-width: 35px;
  }
  .pagination .dots {
    padding: 0;
  }
  .pagination .dots span {
    min-width: 0;
  }
}

#investment-form__localization__description.ta-root .ta-editor, #investment-form__localization__description.ta-root .ta-bind {
  min-height: 100px;
}

.navbar-brand {
  padding-top: 5px;
}

.navbar-brand .panel__logo {
  height: 38px;
}

.panel-header {
  margin-top: 0;
}

.slide--default-in {
  display: block;
}

.slide--default-out {
  display: none;
}

fieldset {
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

fieldset legend {
  margin-bottom: -10px;
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
}

fieldset:first-of-type {
  margin-bottom: 0;
  border-top: 0;
  padding-top: 0;
}

fieldset:first-of-type legend {
  border-top: 0;
  margin-bottom: 10px;
  padding-top: 0;
}

table tr.messages td {
  padding: 0;
  border: 0;
}

table tr.messages td .alert {
  margin: 10px;
}

.map-container {
  height: 250px;
  background: #f4f4f4;
  border: 1px solid #ececec;
  border-top-width: 3px;
  border-radius: 4px;
  overflow: hidden;
}

.map-container .angular-google-map-container {
  height: 250px;
}

.file-picker__preview {
  height: 100px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid #e7e7e7;
  border-top-width: 3px;
  border-radius: 4px;
  padding: 5px;
  background: url(../img/image-preview-bg.png);
}

.file-picker__preview--image img {
  max-width: 100%;
  max-height: 100%;
}

.file-picker__preview--download {
  height: auto;
}

.file-picker__preview--download .glyphicon {
  font-size: 28px;
}

.ta-root .btn-group {
  margin-bottom: 10px;
}

.ta-root .ta-editor, .ta-root .ta-bind {
  min-height: 330px;
}

.objects-list {
  padding: 0 15px;
}

.objects-list__header, .objects-list__filter, .objects-list__content, .objects-list__messages {
  will-change: auto;
  transform: translateZ(0);
}

.objects-list__header .row, .objects-list__filter .row, .objects-list__content .row, .objects-list__messages .row {
  border: 1px solid #eeeeee;
  border-bottom: 0;
  border-left: 0;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.objects-list__header .row, .objects-list__filter .row {
  background: #f9f9f9;
}

.objects-list__messages .row {
  display: block;
}

.objects-list__messages .row .objects-list__col {
  display: block;
  min-height: 0;
  padding: 0;
}

.objects-list__messages .alert {
  margin: 10px 10px;
}

.objects-list__col {
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eeeeee;
  padding: 5px 10px;
}

.objects-list__col__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.objects-list__content .objects-list__col {
  word-break: break-all;
}

.objects-list__content__row--odd {
  background: #f9f9f9;
}

.objects-list__content__row:hover {
  background-color: #f2f9ff;
}

.objects-list__content__image-preview {
  max-width: 200px;
  max-height: 200px;
}

.objects-list__content__attachment-download {
  font-size: 2em;
  text-align: center;
  display: block;
}

.objects-list__label {
  font-weight: bold;
}

.list-buttons {
  text-align: right;
  margin-bottom: 15px;
}

.image-form {
  margin-bottom: 30px;
}

.attachment-form {
  margin-bottom: 30px;
}

.parts-selector__options {
  text-align: right;
}

@media screen and (min-width: 992px) {
  .parts-selector__options .btn {
    margin-top: 26px;
  }
}

.authentication__login__container, .authentication__password-reset__container, .authentication__password-change-token__container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-direction: column;
}

.authentication__login__form, .authentication__password-reset__form, .authentication__password-change-token__form {
  width: 100%;
  max-width: 300px;
}

.authentication__logo {
  margin-bottom: 30px;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

html, body, .full-height {
  height: 100%;
}

.container {
  min-height: 80%;
}

/*# sourceMappingURL=../sourcemaps/css/style.css.map */
