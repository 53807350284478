@import 'pagination.scss';
@import 'investment.scss';

.navbar-brand {
    padding-top: 5px;
}

.navbar-brand .panel__logo {
    height: 38px;
}

.panel-header {
	margin-top: 0;
}

.slide {
	&--default-in {
		display: block;
	}
	
	&--default-out {
		display: none;
	}
}

fieldset {
	margin-bottom: 20px;
	padding-top: 20px;
	border-top: 1px solid #e5e5e5;
	
	legend {
		margin-bottom: -10px;
		border-top: 1px solid #e5e5e5;
		padding-top: 15px;
	}
	
	&:first-of-type {
		margin-bottom: 0;
		border-top: 0;
		padding-top: 0;
		legend {
			border-top: 0;
			margin-bottom: 10px;
			padding-top: 0;
		}
	}
}



table tr.messages td {
	padding: 0;
	border: 0;
	
	.alert {
		margin: 10px;
	}
}

.map-container {
	height: 250px;
	background: #f4f4f4;
	border: 1px solid #ececec;
	border-top-width: 3px;
	border-radius: 4px;
	overflow: hidden;
	
	.angular-google-map-container {
		height: 250px;
	}
	
}

.file-picker__preview {
	height: 100px;
	margin-top: 10px;
	margin-bottom: 20px;
	text-align: center;
	border: 1px solid #e7e7e7;
	border-top-width: 3px;
	border-radius: 4px;
	padding: 5px;
	background: url(../img/image-preview-bg.png);
	
	&--image {
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
	
	&--download  {
		height: auto;
		.glyphicon {
			font-size: 28px;
		}
	}
}

.ta-root {
	.btn-group {
		margin-bottom: 10px;
	}
	
	.ta-editor, .ta-bind {
		min-height: 330px;
	}
}

.objects-list {
	padding: 0 15px;
	
	&__header, &__filter, &__content, &__messages {
		    will-change: auto;
	    transform: translateZ(0);
		.row {
			border: 1px solid #eeeeee;
			border-bottom: 0;
			border-left: 0;
	    	display: flex;
	    	align-items: stretch;
	    	flex-wrap: wrap;
		}
	}
	
	&__header, &__filter {
		.row {
			background: #f9f9f9;
		}
		label {
		}
	}
	
	&__messages {
		.row {
			display: block;
			.objects-list__col {
				display: block;
				min-height: 0;
				padding: 0;
			}
		}
		.alert {
			margin: 10px 10px;
		}
	}
	
	&__col {
   		border-left: 1px solid #eee;
   		border-bottom: 1px solid #eeeeee;
   		padding: 5px 10px;
   		
   		&__buttons {
   			display: flex;
   			justify-content: flex-end;
   			align-items: flex-end;
   			text-align: right;
   		}
   	}
   	
   	&__content {
   		.objects-list__col {
	   		word-break: break-all;
	   	}
	   	
	   	&__row {
	   	    &--odd {
   	            background: #f9f9f9;
	   	    }
	   	    &:hover {
	   	    	background-color: #f2f9ff;
	   	    }
	   	}
	   	
	   	&__image-preview {
	   		max-width: 200px;
	   		max-height: 200px;
	   	}
	   	
	   	&__attachment-download {
	   		font-size: 2em;
	   		text-align: center;
	   		display: block;
	   	}
   	}
   	
   	&__label {
   		font-weight: bold;
   	}
}


.list-buttons {
	text-align: right;
	margin-bottom: 15px;
}



.image-form {
	margin-bottom: 30px;
}

.attachment-form {
	margin-bottom: 30px;
}
