.pagination {
	a {
		cursor: pointer;
	}
	
	@media screen and (max-width: 480px) {
		li {
			.arrow {
				a, span {
					min-width: 30px;
				}
			}
			a, span {
				padding-left: 0;
				padding-right: 0;
				min-width: 35px;
			}
		}
		.dots {
			padding: 0;
			span {
				min-width: 0;
			}
		}
	}
}

